import classes from "./NavigationBar.module.css";
import { Fragment } from "react";

import { Link } from "react-router-dom";

const NavigationBar = (props) => {

  return (
    <Fragment>
      <header className={classes.header}>
        <Link to={`/`}>
          <p className={classes.title}>Wallet</p>
        </Link>

        {/* <Link to={`/orders`}>
          <p className={classes.title}>Orders</p>
        </Link> */}
      </header>
    </Fragment>
  );
};

export default NavigationBar;
