import classes from "./NewOrderButton.module.css";
import { Link } from "react-router-dom";

const NewOrderButton = (props) => {
  return (
    <Link to={`/newOrder/${props.symbol}`}>
      <div className={classes.container}>
      <p className={classes.title}>Add Order</p>
      </div>
    </Link>
  );
};

export default NewOrderButton;