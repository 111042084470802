import classes from "./Wallet.module.css";

const Wallet = (props) => {

let coinName = props.currency.asset.toLowerCase() //'btc'; // здесь может быть любое имя монеты

let icon
if (coinName == "busd") {
    icon = `/busd.svg`;
} else {
    icon = require(`cryptocurrency-icons/svg/color/${coinName}.svg`);
}

const free = (Math.round(props.currency.free * 100) / 100).toFixed(2)
const locked = (Math.round(props.currency.locked * 100) / 100).toFixed(2)

  return (
    <div className={classes.walletContainer}>
      {/* <img className={classes.mainImage} src={props.user.avatar} alt="" />
      <div className={classes.textContainer}>
        <p className={classes.title}>{props.user.name}</p>
        <p className={classes.mmr}>{props.user.mmr}</p>
      </div>
      <div>
        <img className={classes.rankIcon} src={rank.icon} alt="" />
        <p className={classes.rankPercent}>{rank.perc}</p>
      </div> */}
      <img className={classes.icon} src={icon} alt="" />
      <p className={classes.title}>{free}</p>
      <p className={classes.locked}>{"locked: " + locked}</p>
    </div>
  );
};

export default Wallet;
