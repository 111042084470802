import { useParams } from "react-router-dom/cjs/react-router-dom";
import useHttp from "../hooks/use-http";
import { getOrders } from "../network/api";
import { useEffect } from "react";
import NewOrderButton from "../components/Orders/NewOrderButton";
import OrderItem from "../components/Orders/OrderItem";

const Orders = () => {
  const params = useParams();
  const { symbol } = params;

  const { sendRequest, status, data: data, error } = useHttp(getOrders, true);
  useEffect(() => {
    sendRequest(symbol);
  }, [sendRequest]);

  if (!data) {
    return <div></div>;
  }
  console.log(data);

  return (
    <div>
      <NewOrderButton symbol={symbol} />
      {data.map((order) => (
        <OrderItem order={order}></OrderItem>
      ))}
    </div>
  );
};

export default Orders;
