import { useParams } from "react-router-dom/cjs/react-router-dom";
import NewOrderForm from "../components/Orders/NewOrderForm";
import classes from "./NewOrder.module.css";
import { longOrder, shortOrder } from "../network/api";
import useHttp from "../hooks/use-http";
import { useEffect } from "react";


const NewOrder = () => {
  const params = useParams();
  const { symbol } = params;
   
  const { sendRequest, status, data: data, error } = useHttp(longOrder, true);
  const { sendRequest: sendRequest1, status: status1, data: data1, error: error1 } = useHttp(shortOrder, true);

  console.log(data)

  const createLong = (params) => {
    sendRequest(params);
  };

  const createShort = (params) => {
    sendRequest1(params);
  };

  return (
    <div>
      <p className={classes.title}>{symbol}</p>
      <div className={classes.container}>
        <NewOrderForm symbol={symbol} long={true} createLong={createLong} createShort={createShort}></NewOrderForm>
        <NewOrderForm symbol={symbol} long={false} createLong={createLong} createShort={createShort}></NewOrderForm>;
      </div>
    </div>
  );
};

export default NewOrder;
