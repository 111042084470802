import classes from "./NewOrderForm.module.css";
import { useState } from "react";

const NewOrderForm = (props) => {
  const [quantity, setQuantity] = useState(0.0);
  const [lossPrice, setLossPrice] = useState(0.0);
  const [profitPrice, setProfitPrice] = useState(0.0);

  const handleQuantity = (event) => {
    setQuantity(event.target.value);
  };

  const handleLossPrice = (event) => {
    setLossPrice(event.target.value);
  };

  const handleProfitPrice = (event) => {
    setProfitPrice(event.target.value);
  };

  const onSubmit = () => {
    if (quantity) {
      const params = {
        symbol: props.symbol,
        quantity: quantity,
        stopLossPrice: lossPrice,
        takeProfitPrice: profitPrice,
      };
      console.log(params)
      if (props.long) {
        props.createLong(params);
      } else {
        props.createShort(params);
      }
    }
  };

  return (
    <div className={classes.formContainer}>
      <p className={props.long ? classes.longtitle : classes.shorttitle}>
        {" "}
        {props.long ? "Long" : "Short"}
      </p>
      <div className={classes.inputContainer}>
        <p className={classes.title}>Quantity</p>
        <input
          className={classes.textField}
          onChange={handleQuantity}
          type="number"
        />
      </div>

      <div className={classes.inputContainer}>
        <p className={classes.title}>Stop Loss Price</p>
        <input
          className={classes.textField}
          onChange={handleLossPrice}
          type="number"
        />
      </div>

      <div className={classes.inputContainer}>
        <p className={classes.title}>Take Profit Price</p>
        <input
          className={classes.textField}
          onChange={handleProfitPrice}
          type="number"
        />
      </div>

      <p className={classes.error}>{props.errorMessage}</p>

      <button className={classes.button} onClick={onSubmit}>
        {props.long ? "Long Order" : "Short Order"}
      </button>
    </div>
  );
};

export default NewOrderForm;
