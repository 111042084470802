export async function getWallet() {
    const response = await fetch(`/api/account`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch product.");
    }
    return data;
  }

  export async function getOrders(symbol) {
    const response = await fetch(`/api/allOrders`, {
        method: "POST",
        body: JSON.stringify({symbol: symbol}),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data)

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch product.");
    }
    return data;
  }

  export async function longOrder(params) {
    const response = await fetch(`/api/long`, {
        method: "POST",
        body: JSON.stringify(params),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data)

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch product.");
    }
    return data;
  }

  export async function shortOrder(params) {
    const response = await fetch(`/api/short`, {
        method: "POST",
        body: JSON.stringify(params),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data)

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch product.");
    }
    return data;
  }