import Main from "./pages/Main";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Orders from "./pages/Orders";
import NewOrder from "./pages/NewOrder";

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Main></Main>
        </Route>
        <Route path="/orders/:symbol" exact>
          <Orders></Orders>
        </Route>
        <Route path="/newOrder/:symbol" exact>
          <NewOrder></NewOrder>
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
