import classes from "./OrederItem.module.css"

const OrderItem = (props) => {
    let order = props.order

    const quantity = (Math.round(order.origQty * 100) / 100).toFixed(2)
    let price = (Math.round(order.origQty * 100) / 100).toFixed(2)

    // if (order.type != "MARKET") {
        price = (Math.round(order.price * 100) / 100).toFixed(2)
    // }

  return (
    <div className={classes.container}>
      <p className={(order.side == "SELL") ? classes.sellTitle : classes.buyTitle}>{order.side}</p>
      <p className={classes.title}>{order.type}</p>
      <p className={classes.title}>{order.symbol}</p>
      <p className={classes.title}>{quantity}</p>
      <p className={classes.title}>{price}</p>
    </div>
  );
};

export default OrderItem;