import { getWallet } from "../network/api";
import { React, useEffect } from "react";
import useHttp from "../hooks/use-http";
import Wallet from "../components/Wallet/Wallet";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Main = () => {
  const { sendRequest, status, data: data, error } = useHttp(getWallet, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  if (!data) {
    return <div></div>;
  }

  return (
    <div>
      {data.balances.map((currency) => (
        <Link to={`/orders/${currency.asset == "USDT" ? "USDTBTC" : currency.asset + "USDT"}`}>
          <Wallet currency={currency} key={currency.asset} />
        </Link>
      ))}
    </div>
  );
};

export default Main;
